<template>
  <b-overlay
    :show="showOverlay"
    rounded="sm"
    spinner-variant="primary"
    spinner-type="grow"
  >
    <div class="auth-wrapper auth-v2">
      <b-row class="auth-inner m-0">

        <!-- Brand logo-->
        <b-link class="brand-logo">
          <b-img
            :src="appLogoImage"
            alt="logo"
          />
          <h2 class="brand-text text-primary ml-1">
            {{ appName }}
          </h2>
        </b-link>
        <!-- /Brand logo-->

        <!-- Left Text-->
        <b-col
          lg="8"
          class="d-none d-lg-flex align-items-center auth-left-bg p-5"
        >
          <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
            <b-img
              fluid
              :src="imgUrl"
              alt="Login V2"
            />
          </div>
        </b-col>
        <!-- /Left Text-->

        <!-- Login-->
        <b-col
          lg="4"
          class="d-flex align-items-center auth-bg px-2 p-lg-5"
        >
          <b-col
            sm="8"
            md="6"
            lg="12"
            class="px-xl-2 mx-auto"
          >
            <b-card-title
              class="mb-1 font-weight-bold"
              title-tag="h2"
            >
              Welcome to Flourishing!
            </b-card-title>
            <b-card-text class="mb-2">
              Sign in to get started
            </b-card-text>
            <!-- form -->
            <validation-observer
              ref="loginForm"
              #default="{invalid}"
            >
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent="login"
              >
                <!-- email -->
                <b-form-group
                  label="Email"
                  label-for="login-email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    vid="email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="login-email"
                      v-model="userEmail"
                      :state="errors.length > 0 ? false:null"
                      name="login-email"
                      placeholder="john@example.com"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- forgot password -->
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="login-password">Password</label>
                    <b-link :to="{name:'auth-forgot-password'}">
                      <small>Forgot Password?</small>
                    </b-link>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    vid="password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="login-password"
                        v-model="password"
                        :state="errors.length > 0 ? false:null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="login-password"
                        placeholder="Password"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- checkbox
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Remember Me
                </b-form-checkbox>
              </b-form-group> -->

                <!-- submit buttons -->
                <b-button
                  type="submit"
                  variant="primary"
                  block
                  :disabled="invalid"
                  name="login"
                >
                  <span
                    v-if="showSpinner"
                    class="spinner-border spinner-border-sm spinner-in-button"
                    role="status"
                    aria-hidden="true"
                  />
                  Sign in
                </b-button>
                <small
                  v-if="loginError"
                  class="text-danger"
                >Error: Login Failed. Please check details and try again</small>
              </b-form>
            </validation-observer>

            <b-card-text class="text-center mt-2">
              <span>New to Flourishing? </span>
              <b-link :to="{name:'auth-register'}">
                <span>&nbsp;Create an account</span>
              </b-link>
            </b-card-text>
          </b-col>
        </b-col>
        <!-- /Login-->
      </b-row>
    </div>
    <footer>
      <cookie-law
        theme="aequip"
      >
        <div slot="message">
          <p>This app uses cookies to ensure you get the best experience.
            <b-link
              target="_blank"
              href="https://pthr.co.uk/privacy-and-cookies/"
            >
              Click here to view our Cookie Policy
            </b-link></p>
        </div>
      </cookie-law>
    </footer>
  </b-overlay>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BCardText, BCardTitle, BImg, BForm, BButton, VBTooltip, BOverlay,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser, roleToDisplayName, getAbilityFromRole } from '@/auth/utils'
import { $themeConfig } from '@themeConfig'
import router from '@/router'
import { initialAbility } from '@/libs/acl/config'
import CookieLaw from 'vue-bootstrap-cookie-law'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    CookieLaw,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      showSpinner: false,
      sideImg: require('@/assets/images/pages/login-v2.svg'),

      // validation rules
      required,
      email,
      loginError: false,
      oldPassword: null,
      showOverlay: false,
    }
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
  computed: {

    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  created() {
    if (router.currentRoute.params.pass && router.currentRoute.params.user) {
      this.showOverlay = true
      this.logout()
      this.userEmail = atob(decodeURIComponent(router.currentRoute.params.user))
      this.password = atob(decodeURIComponent(router.currentRoute.params.pass))
      this.internalLogin()
    }
  },
  methods: {
    logout() {
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Reset ability
      this.$ability.update(initialAbility)
    },
    login() {
      this.loginError = false
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.showSpinner = true
          this.internalLogin()
        }
      })
    },
    internalLogin() {
      this.$http.post('/auth', {
        userName: this.userEmail,
        password: this.password,
      })
        .then(response => {
          // Redirect to update password
          if (response.data.newPasswordRequired) {
            this.$router.replace({ name: 'auth-create-password', params: { user: router.currentRoute.params.user, pass: router.currentRoute.params.pass } })
          } else {
            const userData = JSON.parse(atob(response.data.userData.split('.')[1]))
            useJwt.setToken(response.data.userData)
            useJwt.setRefreshToken(response.data.refreshToken)
            userData.role = userData['custom:role']
            userData.ability = getAbilityFromRole(userData.role)
            userData.displayRole = roleToDisplayName(userData.role)
            console.log(response.data)
            const companyData = JSON.parse(response.data.companyData)
            userData.companyName = companyData.companyName
            userData.avatar = require('@/assets/images/avatars/default.png')
            delete (userData.sub)
            localStorage.setItem('userData', JSON.stringify(userData))

            this.$ability.update(userData.ability)

            this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: `Welcome ${userData.given_name}`,
                    timeOut: 2500,
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: `You have successfully logged in as ${userData.displayRole}`,
                  },
                })
              })
          }
        })
        .catch(error => {
          this.showOverlay = false

          this.showSpinner = false
          console.log('error:', error)
          this.loginError = true
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
